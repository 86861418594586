import TepeConnectProfileResponse from '../TepeConnectProfilePage/Models/TepeConnectProfileResponse.interface';
import TepeConnectProfilePutModel from '../TepeConnectProfilePage/Models/TepeConnectProfilePutModel.interface';

export async function EditProfile(
  url: string,
  data: TepeConnectProfilePutModel,
  onSuccess: (data: TepeConnectProfileResponse) => void,
  onError: (data?: TepeConnectProfileResponse) => void
) {
  try {
    const res = await fetch(`${url}/UpdateTepeConnectProfile`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        credentials: 'same-origin',
      },
      body: JSON.stringify(data),
    });

    const jsonRes = (await res.json()) as TepeConnectProfileResponse;
    if (jsonRes.statusCode !== 200) {
      onError(jsonRes);
      return;
    }

    onSuccess(jsonRes);
  } catch (e) {
    onError();
  }
}
