import React, { useState } from 'react';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import { media, pseudo } from '@glitz/core';
import { applyEditModeAttr } from '../../Features/Shared/Common/Helpers';
import { styled, theme } from '../Theme';
import XhtmlComponent from '../Shared/XhtmlComponent/XhtmlComponent';
import Breadcrumbs from '../Shared/Breadcrumb/Breadcrumb';
import KexInput from '../Shared/Input/KexInput';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import GenericDropDown from '../Shared/ValueDropDown/GenericDropDown';
import { KexInputValidation } from '../Shared/Input/KexInputValidation';
import { FeedbackButton, SmallButton } from '../Shared/Button/Button';
import IconLink from '../Shared/IconLink/IconLink';
import { LinkType } from '../Enums/LinkType.enum';
import { SignOut } from '../AccountPage/Account';
import TepeConnectProfilePageModel from '../TepeConnectProfilePage/Models/TepeConnectProfilePageModel.interface';
import KexRadio from '../Shared/KexRadio/KexRadio';
import TepeNotification from '../Shared/Common/TepeNotification';
import { EditProfile } from './EditProfile';
import TepeConnectRegistrationResponse from '../TepeConnectRegistrationPage/Models/TepeConnectRegistrationResponse.interface';
import { translate } from '../Shared/Common/Translate';
import TepeConnectUserViewModel from '../TepeConnectProfilePage/Models/TepeConnectUserViewModel.interface';

function TepeConnectProfilePage() {
  const {
    pageHeading,
    pageSubHeading,
    inEditMode,
    pageInfoText,
    user,
  } = useCurrentPage<TepeConnectProfilePageModel>();
  const [formValid, setFormValid] = useState<boolean>(false);
  const [userFields, setUserFields] = useState({
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: user?.email,
    phoneNumber: user?.phoneNumber,
    gdcidcNumber: user?.gdcidcNumber,
    dentalSchool: user?.dentalSchool,
    courseOfStudy: user?.courseOfStudy,
  } as TepeConnectUserViewModel);
  const [currentTitle, setCurrentTitle] = useState<string>(user?.title || '');
  const [currentRole, setCurrentRole] = useState<string>(
    user?.currentRole || ''
  );
  const [dentalProfessionalOption, setDentalProfessionalOption] = useState<
    boolean
  >(user?.qualifiedDentalProfessional || false);

  const [dentalStudentOption, setDentalStudentOption] = useState<boolean>(
    !user?.qualifiedDentalProfessional || false
  );
  const [errorMessage, setErrorMessage] = useState<string[]>([]);
  const [successMessage, setSuccessMessage] = useState<string>('');

  const {
    languageRoute,
    staticPages: { resetPasswordPage, accountPage, tepeConnectProfilePage },
    translations: {
      'account/signOut': signOut,
      'common/generalError': generalError,
      'tepeConnect/fields/firstname': firstname,
      'tepeConnect/fields/surname': surname,
      'tepeConnect/fields/email': email,
      'tepeConnect/fields/phoneNumber': phoneNumber,
      'tepeConnect/fields/dentalProfessional': dentalProfessional,
      'tepeConnect/fields/dentalStudent': dentalStudent,
      'tepeConnect/fields/dentalSchool': dentalSchool,
      'tepeConnect/fields/studyCourse': courseOfStudy,
      'tepeConnect/fields/GDCIDC': GDCIDC,
      'tepeConnect/update': submitText,
      'tepeConnect/validation/invalidPhoneNumber': invalidPhoneNumber,
      'tepeConnect/validation/invalidEmail': invalidEmail,
      'tepeConnect/validation/invalidLength': invalidLength,
      'tepeConnect/titleValues/label': titleLabel,
      'tepeConnect/titleValues/prof': profValue,
      'tepeConnect/titleValues/dr': drValue,
      'tepeConnect/titleValues/mr': mrValue,
      'tepeConnect/titleValues/mrs': mrsValue,
      'tepeConnect/titleValues/ms': msValue,
      'tepeConnect/titleValues/miss': missValue,
      'tepeConnect/titleValues/other': otherValue,
      'tepeConnect/roleValues/label': roleLabel,
      'tepeConnect/roleValues/dentist': dentistValue,
      'tepeConnect/roleValues/dentalHygienist': dentalHygienistValue,
      'tepeConnect/roleValues/dentalTherapist': dentalTherapistvalue,
      'tepeConnect/roleValues/dentalNurse': dentalNurseValue,
      'tepeConnect/roleValues/dentalStudent': dentalStudentValue,
      'tepeConnect/roleValues/other': roleOtherValue,
      'common/resetPassword': resetPasswordLabel,
    },
  } = useAppSettingsData();

  const updateUserField = (value: string, name?: string) => {
    if (name) {
      setUserFields((prevState: any) => ({
        ...prevState,

        [name]: value,
      }));
    }
  };
  const getUserFieldsPostdata = () => {
    return {
      ...userFields,
      ...(dentalStudentOption && {
        dentalStudent: true,
      }),
      ...(dentalProfessionalOption && {
        qualifiedDentalProfessional: true,
        ...(currentRole && {
          currentRole: currentRole,
        }),
      }),
      ...(currentTitle && {
        title: currentTitle,
      }),
      qualifiedDentalProfessional: dentalProfessionalOption,
    };
  };
  const postRegistrationForm = async () => {
    setErrorMessage([]);
    const data = getUserFieldsPostdata();
    setSuccessMessage('');

    await EditProfile(tepeConnectProfilePage, data, onSuccess, onError);
  };

  const onSuccess = (data: TepeConnectRegistrationResponse) => {
    setSuccessMessage(data.message);
  };

  const onError = (data?: TepeConnectRegistrationResponse) => {
    data && setErrorMessage(data.errors);
    !data && setErrorMessage([generalError]);
  };

  const setOptionDentalProfessional = () => {
    setDentalStudentOption(false);
    setDentalProfessionalOption(true);
    userFields.dentalSchool = '';
    userFields.courseOfStudy = '';
  };

  const setOptionDentalStudent = () => {
    setDentalStudentOption(true);
    setDentalProfessionalOption(false);
    userFields.gdcidcNumber = '';
    userFields.currentRole = '';
  };

  const DentalProfessionalFields = (
    <>
      <GenericDropDown
        values={[
          dentistValue,
          dentalHygienistValue,
          dentalTherapistvalue,
          dentalNurseValue,
          dentalStudentValue,
          roleOtherValue,
        ]}
        currentValue={currentRole}
        setSelectedValue={setCurrentRole}
        ghostLabel={roleLabel}
      />

      <InputWrapper>
        <KexInput
          title={GDCIDC + '*'}
          onChange={updateUserField}
          name="gdcidcNumber"
          value={userFields?.gdcidcNumber || ''}
          validation={{
            required: true,
            minLength: 1,
            errorMessage: translate(invalidLength, [1]).join(' '),
          }}
          useDebounce
        />
      </InputWrapper>
    </>
  );

  const DentalStudentFields = (
    <>
      <InputWrapper>
        <KexInput
          title={dentalSchool + '*'}
          onChange={updateUserField}
          name="dentalSchool"
          value={userFields?.dentalSchool || ''}
          validation={{
            required: true,
            minLength: 1,
            errorMessage: translate(invalidLength, [1]).join(' '),
          }}
          useDebounce
        />
      </InputWrapper>
      <InputWrapper>
        <KexInput
          title={courseOfStudy + '*'}
          onChange={updateUserField}
          name="courseOfStudy"
          value={userFields?.courseOfStudy || ''}
          validation={{
            required: true,
            minLength: 1,
            errorMessage: translate(invalidLength, [1]).join(' '),
          }}
          useDebounce
        />
      </InputWrapper>
    </>
  );

  return (
    <>
      <BreadCrumbContainer>
        <Breadcrumbs />
      </BreadCrumbContainer>
      <Wrapper>
        {(pageHeading || pageSubHeading || pageSubHeading) && (
          <TopContent>
            {pageHeading && (
              <Heading {...applyEditModeAttr(inEditMode && 'Heading')}>
                {pageHeading}
              </Heading>
            )}
            {pageSubHeading && (
              <Intro {...applyEditModeAttr(inEditMode && 'IntroText')}>
                {pageSubHeading}
              </Intro>
            )}
            {pageInfoText && (
              <BodyText>
                <XhtmlComponent
                  {...applyEditModeAttr(inEditMode && 'BodyText')}
                  content={pageInfoText}
                />
              </BodyText>
            )}
          </TopContent>
        )}
        <FormWrapper>
          <KexInputValidation
            onInputsValid={() => {
              setFormValid(true);
            }}
            onInputsInvalid={() => {
              setFormValid(false);
            }}
          >
            <GenericDropDown
              values={[
                profValue,
                drValue,
                mrValue,
                mrsValue,
                msValue,
                missValue,
                otherValue,
              ]}
              currentValue={currentTitle}
              setSelectedValue={setCurrentTitle}
              ghostLabel={titleLabel}
            />
            <InputWrapper>
              <KexInput
                title={firstname + '*'}
                validation={{
                  required: true,
                  minLength: 3,
                  maxLength: 10,
                  errorMessage: translate(invalidLength, [3]).join(' '),
                }}
                onChange={updateUserField}
                name="firstName"
                value={userFields?.firstName || ''}
                useDebounce
              />
            </InputWrapper>
            <InputWrapper>
              <KexInput
                title={surname + '*'}
                validation={{
                  required: true,
                  minLength: 1,
                  errorMessage: translate(invalidLength, [1]).join(' '),
                }}
                onChange={updateUserField}
                name="lastName"
                value={userFields?.lastName || ''}
                useDebounce
              />
            </InputWrapper>
            <InputWrapper>
              <KexInput
                title={email + '*'}
                validation={{
                  required: true,
                  minLength: 1,
                  maxLength: 40,
                  pattern: /(?=^.{1,241}$)^([\w.-]+)@([\w-]+)((\.(\w){2,})+)$/,
                  errorMessage: invalidEmail,
                }}
                onChange={updateUserField}
                name="email"
                value={userFields?.email || ''}
                useDebounce
              />
            </InputWrapper>
            <InputWrapper>
              <KexInput
                title={phoneNumber + '*'}
                validation={{
                  required: true,
                  pattern: /(^[\\s\-+()0-9]{1,16}$)/,
                  errorMessage: invalidPhoneNumber,
                }}
                onChange={updateUserField}
                name="phoneNumber"
                value={userFields?.phoneNumber || ''}
                useDebounce
              />
            </InputWrapper>
            <ChoicesWrapper>
              <KexRadio
                onChange={setOptionDentalProfessional}
                isChecked={dentalProfessionalOption}
                label={dentalProfessional}
              />

              <KexRadio
                onChange={setOptionDentalStudent}
                isChecked={dentalStudentOption}
                label={dentalStudent}
              />
            </ChoicesWrapper>
            {dentalStudentOption && DentalStudentFields}
            {dentalProfessionalOption && DentalProfessionalFields}
          </KexInputValidation>
        </FormWrapper>
        <ButtonContainer>
          <IconLink href={resetPasswordPage} type={LinkType.Arrow}>
            {resetPasswordLabel}
          </IconLink>
          <ForwardButton disabled={!formValid} action={postRegistrationForm}>
            {submitText}
          </ForwardButton>
        </ButtonContainer>

        {!!errorMessage?.length &&
          errorMessage.map((msg, _i) => (
            <TepeNotification key={_i} isError={true} text={msg} />
          ))}

        {!!successMessage && (
          <TepeNotification isError={false} text={successMessage} />
        )}
        <LogoutButton onClick={() => SignOut(accountPage, languageRoute)}>
          {signOut}
        </LogoutButton>
      </Wrapper>
    </>
  );
}

const BreadCrumbContainer = styled.div({
  ...media(theme.mediaQuery.mediaMaxLarge, {
    margin: { x: theme.spacing(4) },
  }),
});

const ChoicesWrapper = styled.div({
  margin: { y: theme.spacing(6) },
});

const InputWrapper = styled.div({
  ...pseudo(':not(:last-child)', {
    marginBottom: theme.spacing(2),
  }),
});

const TopContent = styled.div({
  margin: { top: theme.spacing(2), bottom: theme.spacing(6) },
  width: '100%',
  color: theme.black,
  maxWidth: theme.blockMaxWidthMobile,

  ...media(theme.mediaQuery.mediaMinLarge, {
    margin: { bottom: theme.spacing(4), top: theme.none, x: 'auto' },
    padding: {
      x: theme.none,
    },
  }),
  ...media(theme.mediaQuery.mediaMinMedium, {
    margin: { x: 'auto' },
    maxWidth: theme.contentMaxWidth,
  }),
});

const Heading = styled.h1({
  maxWidth: theme.maxWidthSmall,
  font: { size: theme.theta },
  marginBottom: theme.spacing(6),
  ...media(theme.mediaQuery.mediaMinLarge, {
    font: { size: theme.iota },
    marginBottom: theme.spacing(4),
  }),
});

const Intro = styled.p({
  font: { size: theme.delta, weight: theme.fontWeight.bold },
  lineHeight: theme.lineHeight.normal,
  letterSpacing: theme.letterSpacing.nearMedium,
  marginBottom: theme.spacing(2),
});

const BodyText = styled.div({ width: '100%' });

const Wrapper = styled.div({
  width: '100%',
  margin: { x: 'auto', bottom: theme.spacing(20) },
  padding: { x: theme.spacing(4) },
  ...media(theme.mediaQuery.mediaMinLarge, {
    width: '100%',
    maxWidth: theme.spacing(100),
    margin: { x: 'auto', bottom: theme.spacing(20) },
  }),
});

const FormWrapper = styled.div({});

const ButtonContainer = styled.div({
  display: 'flex',
  alignItems: 'start',
  margin: { x: 'auto', y: theme.spacing(4) },
  ...media(theme.mediaQuery.mediaMaxLarge, {
    flexDirection: 'column-reverse',
  }),
  ...media(theme.mediaQuery.mediaMinLarge, {
    justifyContent: 'center',
    alignItems: 'center',
  }),
});

const ForwardButton = styled(FeedbackButton, {
  font: { size: theme.gamma, weight: theme.fontWeight.bold },
  marginLeft: 'auto',
  letterSpacing: theme.letterSpacing.medium,
  padding: { x: theme.spacing(8), y: theme.spacing(3) },
  width: '100%',
  height: theme.spacing(11.5),
  marginBottom: theme.spacing(12),
  ...media(theme.mediaQuery.mediaMinLarge, {
    width: 'auto',
    marginBottom: theme.none,
    maxWidth: theme.spacing(100),
  }),
});

const LogoutButton = styled(SmallButton, {
  font: { size: theme.gamma, weight: theme.fontWeight.normal },
  letterSpacing: theme.letterSpacing.medium,
  backgroundColor: theme.white,
  height: theme.spacing(11.5),
  padding: { y: theme.spacing(2.5) },
  color: theme.blueDark,
  margin: { top: theme.spacing(4) },
  width: '100%',
  border: {
    xy: {
      style: 'solid',
      width: theme.spacing(0.5),
      color: theme.blueDark,
    },
  },
});

export default TepeConnectProfilePage;
